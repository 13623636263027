import { useQuery } from '@tanstack/react-query'
import {
    api,
    CacheKeys,
    client,
    getCache,
    queryClient,
    setCache,
} from './lib/client'
import { getCurrentBranchId } from './lib/state'
import { captureException } from '@sentry/remix'

// utils/queryUtils.ts
export async function fetchWithCache<T>(
    cacheKey: (string | number)[],
    networkFetch: () => Promise<T>
): Promise<T | { error: string }> {
    try {
        const dataFromNetwork = await networkFetch()
        if ('error' in dataFromNetwork) {
            throw new Error('Network error')
        }
        setCache(cacheKey, dataFromNetwork)
        return dataFromNetwork
    } catch (error) {
        const dataFromCache = getCache(cacheKey)
        if ('empty_cache' in dataFromCache) {
            return { error: 'No internet connection' }
        }
        return dataFromCache
    }
}

export function useGetOperators() {
    return useQuery({
        enabled: Boolean(getCurrentBranchId()),

        queryKey: [CacheKeys.getOperators],
        queryFn: async () => {
            return await client.api.apiBranchOperatorsList({
                branchId: getCurrentBranchId(),
            })
        },
    })
}

export function useYearlyReports(year: string) {
    return useQuery({
        queryKey: ['year reports', Number(year)],
        queryFn: async () => {
            const res =
                await client.api.apiToxicReportMonthlyReportsByYearRetrieve({
                    year: year,
                    org_branch: getCurrentBranchId(),
                })

            if (res.error) {
                captureException(res.error)
                return []
            }
            return res
        },
    })
}

export function getAllPools() {
    return queryClient.fetchQuery({
        retry: false,

        queryKey: [CacheKeys.getAllPools],
        queryFn: () =>
            fetchWithCache([CacheKeys.getAllPools], () =>
                client.api.apiPoolList({
                    organization_branch: Number(getCurrentBranchId()),
                })
            ),
    })
}

export function useGetAllPools() {
    return useQuery({
        enabled: Boolean(getCurrentBranchId()),
        retry: false,
        placeholderData: { results: [] },
        queryKey: [CacheKeys.getAllPools],
        queryFn: () =>
            fetchWithCache([CacheKeys.getAllPools], () =>
                client.api.apiPoolList({
                    organization_branch: Number(getCurrentBranchId()),
                })
            ),
    })
}

export function useGetPoolTypes(): { data: any } {
    return useQuery({
        placeholderData: [],
        queryKey: ['pool types'],
        queryFn: async () => {
            const res = await client.api.apiPoolTypesList()
            return res.results
        },
    })
}

export function useGetPlans() {
    return useQuery({
        queryKey: ['get plans'],
        queryFn: async () => {
            const orgBranch = await getOrganizationBranch()
            const res = await client.api.apiPlansList({
                plan__state: orgBranch.country,
            })
            return res.results
        },
    })
}
export function getComplexTypes() {
    return queryClient.fetchQuery({
        queryKey: ['complex types'],
        queryFn: async () => {
            const res = await client.api.apiOrganizationTypesList({})
            return res.results
        },
    })
}

export function useGetComplexType(): { data: any } {
    return useQuery({
        placeholderData: [],
        queryKey: ['complex types'],
        queryFn: async () => {
            const res = await client.api.apiOrganizationTypesList()
            return res.results
        },
    })
}

export function useGetUser(id: string) {
    return useQuery({
        enabled: Boolean(getCurrentBranchId()),

        queryKey: [CacheKeys.getUser, id],
        queryFn: async () => {
            return await client.api.apiBranchOperatorsRetrieve(
                getCurrentBranchId(),
                id
            )
        },
    })
}

export function getOrganizationBranch() {
    const branch = getCurrentBranchId()
    return queryClient.fetchQuery({
        staleTime: 3000,
        queryKey: [CacheKeys.getBranch, branch],
        queryFn: async () => {
            return await client.api.apiOrganizationBranchRetrieve(branch)
        },
    })
}

export function useGetCountries() {
    return useQuery({
        staleTime: 3000,
        queryKey: [CacheKeys.getCountries],
        queryFn: async () => {
            return await client.api.apiCountriesList({})
        },
    })
}

export function useGetOrganizationBranch() {
    const branch = getCurrentBranchId()
    return useQuery({
        enabled: Boolean(branch),

        staleTime: 3000,
        queryKey: [CacheKeys.getBranch, branch],
        queryFn: async () => {
            return await client.api.apiOrganizationBranchRetrieve(branch)
        },
    })
}

export function useGetHazards() {
    return useQuery({
        queryKey: ['hazards'],
        queryFn: () =>
            fetchWithCache([CacheKeys.getToxicSubstanceList], () =>
                client.api.apiToxicSubstanceList({})
            ),
        staleTime: 6000 * 60 * 24,
    })
}

export function getHazards() {
    return queryClient.fetchQuery({
        queryKey: [CacheKeys.getToxicSubstanceList],
        queryFn: () =>
            fetchWithCache([CacheKeys.getToxicSubstanceList], () =>
                client.api.apiToxicSubstanceList({})
            ),
        staleTime: 6000 * 60 * 24,
    })
}

export function useGetAllAffiliatedBranches() {
    return useQuery({
        queryKey: ['my branches'],
        queryFn: () =>
            fetchWithCache([CacheKeys.getBranch, 'all'], () =>
                client.api.apiOrganizationBranchList({})
            ),
        staleTime: 6000 * 60 * 24,
    })
}

export function useGetPool(pool_id: string | undefined) {
    return useQuery({
        queryKey: ['pool', pool_id],
        queryFn: () => client.api.apiPoolRetrieve(Number(pool_id)),
        staleTime: 6000 * 60 * 24,
    })
}
